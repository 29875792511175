import { useEffect } from "react";
import { useForm } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

export const PartnerEdit: React.FC = () => {
  const {
    refineCore: { onFinish, formLoading, queryResult },
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  // console.log(register("partner_name"));

  return (
    <div className="container mx-auto">
      <br />
      <form onSubmit={handleSubmit(onFinish)}>
        <div className="mb-6">
          <label
            htmlFor="partner_name"
            className="mb-2 block text-sm font-medium"
          >
            Name
          </label>
          <input
            {...register("partner_name", { required: true })}
            type="text"
            id="partner_name"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm "
            placeholder="Title"
          />
          {errors.partner_name && (
            <p className="mt-1 text-sm text-red-600">
              <span className="font-medium">Oops!</span> This field is required
            </p>
          )}
        </div>
        <div className="mb-6">
          <label htmlFor="status" className="mb-2 block text-sm font-medium">
            Status
          </label>
          <select
            {...register("status")}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          >
            <option value="on-hold">on-hold</option>
            <option value="applying">applying</option>
            <option value="denied">denied</option>
            <option value="approved">approved</option>
            <option value="assessing">assessing</option>
            <option value="open">open</option>
          </select>
        </div>
        <div className="mb-6">
          <label htmlFor="priority" className="mb-2 block text-sm font-medium">
            Priority
          </label>
          <select
            {...register("priority")}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          >
            <option value="LOW">LOW</option>
            <option value="NORMAL">NORMAL</option>
            <option value="HIGH">HIGH</option>
            <option value="none">none</option>
          </select>
        </div>
        <div className="mb-6">
          <label htmlFor="location" className="mb-2 block text-sm font-medium">
            Location
          </label>
          <input
            {...register("location", { required: false })}
            type="text"
            id="location"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm "
            placeholder="Location"
          />
          {errors.location && (
            <p className="mt-1 text-sm text-red-600">
              <span className="font-medium">Oops!</span> This field is required
            </p>
          )}
        </div>
        <div className="mb-6">
          <label htmlFor="status" className="mb-2 block text-sm font-medium">
            Status
          </label>
          <select
            {...register("status")}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          >
            <option value="on-hold">on-hold</option>
            <option value="applying">applying</option>
            <option value="denied">denied</option>
            <option value="approved">approved</option>
            <option value="assessing">assessing</option>
            <option value="open">open</option>
          </select>
        </div>
        <div className="mb-6">
          <label
            htmlFor="description"
            className="mb-2 block text-sm font-medium"
          >
            Description
          </label>
          <textarea
            {...register("description", { required: true })}
            id="content"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm "
            placeholder="description"
            rows={10}
          />
          {errors.description && (
            <p className="mt-1 text-sm text-red-600">
              <span className="font-medium">Oops!</span> This field is required
            </p>
          )}
        </div>
        <button
          type="submit"
          className="flex w-full items-center rounded-lg bg-indigo-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-600 sm:w-auto"
        >
          {formLoading && <span>loading</span>}
          <span>Save</span>
        </button>
      </form>
    </div>
  );
};
