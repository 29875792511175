import { useLogin } from "@pankod/refine-core";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "@pankod/refine-antd";

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const Login = () => {
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login } = useLogin<ILoginForm>();

  return (
    <div className="h-screen  flex justify-center ">
      <div className="lg:p-20 flex items-center justify-center w-full lg:w-auto">
        <div className="flex flex-col gap-6 w-full lg:w-80 px-4">
          <div className="relative flex justify-center ">
            <img src="./images/logo.png" alt="Logo" />
          </div>
          <Form<ILoginForm>
            className="flex flex-col gap-4"
            form={form}
            onFinish={(values) => {
              login(values);
            }}
            requiredMark={false}
            initialValues={{
              remember: false,
            }}
          >
            <Form.Item
              name="username"
              className="focus:outline-none focus:ring-2 rounded-lg text-sm"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Enter your email"
                className=" focus:outline-none  rounded-lg text-sm w-full"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true }]}
              className="focus:outline-none focus:ring-2 rounded-lg text-sm"
            >
              <Input
                type="password"
                placeholder="Enter password"
                className=" focus:outline-none  rounded-lg text-sm w-full"
              />
            </Form.Item>
            <div style={{ marginBottom: "12px" }}>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Remember me
                </Checkbox>
              </Form.Item>
            </div>
            <Button
              className="bg-ultramarine-violet text-white rounded text-sm flex justify-center h-12 items-center"
              htmlType="submit"
              block
            >
              Sign in
            </Button>
          </Form>

          <div className="text-sm text-center">
            <Link className="text-summer-sky" to="/signup">
              Create account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
