import { XCircleIcon } from "@heroicons/react/outline";

function Modal({ showModal, setShowModal, children }: any) {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <XCircleIcon
              className="h-10 text-white absolute top-10 right-10 cursor-pointer"
              onClick={() => setShowModal(false)}
            />
            <div className="relative w-auto sm:w-2/3 my-6 mx-auto max-w-3xl border">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {children}
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Modal;
