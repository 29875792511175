import React, { useState } from "react";
import { supabaseClient } from "utility";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const SignUp = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabaseClient.auth.signUp({
      email,
      password,
    });

    if (error) {
      setLoading(false);
      toast.error(error.message);
    } else {
      toast.error('Registration successful');
      navigate("login");
    }
  };

  return (
    <div className="h-screen flex justify-center ">
      <div className="p-4 lg:p-20 flex items-center justify-center w-full lg:w-auto">
        <div className="flex flex-col gap-6 w-full lg:w-80">
          <div className=" flex justify-center ">
            <img src="./images/logo.png" alt="Logo" />
          </div>

          <form className="flex flex-col gap-4" onSubmit={onSubmit}>
            <input
              className="border text-sm border-gray-400 focus:outline-none focus:ring-2 p-2 rounded-lg "
              type="email"
              id="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              required
            />
            <input
              className="border text-sm border-gray-400 focus:outline-none focus:ring-2 p-2 rounded-lg "
              type="password"
              id="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              required
            />

            <button
              className="bg-ultramarine-violet text-white rounded font-semibold h-12 flex items-center justify-center"
              type="submit"
            >
              {loading ? (
                <span className="p-4">Please wait...</span>
              ) : (
                <span className="p-4">Signup</span>
              )}
            </button>
          </form>

          <div className="text-sm text-center">
            <Link className="text-summer-sky" to="/login">
              Sign In
            </Link>
          </div>

          <div>
            <div className="h-8 w-8 bg-summer-sky"></div>
            <div className="h-8 w-8 bg-black-haze"></div>
            <div className="h-8 w-8 bg-all-gobi"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
