// import { IPost, ICategory } from "interfaces";

export const CourseShow: React.FC = ({ courseData }: any) => {
    return (
      <div className="container mx-auto p-8">
        <div className="mb-6">
          <label className="mb-2 block text-sm font-medium">Name</label>
          <input
            value={courseData?.name}
            disabled
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          />
        </div>
        <div className="mb-6">
          <label className="mb-2 block text-sm font-medium">Status</label>
          <input
            value={courseData?.status}
            disabled
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          />
        </div>
        <div className="mb-6">
          <label className="mb-2 block text-sm font-medium">curriculum</label>
          <input
            value={courseData?.curriculum}
            disabled
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          />
        </div>
        <div className="mb-6">
          <label className="mb-2 block text-sm font-medium">Code</label>
          <textarea
            value={courseData?.code}
            disabled
            rows={10}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          />
        </div>
      </div>
    );
  };
  