import React from "react";
import { Column } from "@pankod/refine-react-table";
import { Table } from "components/Table";

export const ActivitiesList: React.FC = () => {
  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
      },
      {
        id: "owner",
        Header: "owner",
        accessor: "owner",
      },
      {
        id: "due_date",
        Header: "due date",
        accessor: "due_date",
      },

      {
        id: "status",
        Header: "status",
        accessor: "status",
      },
      {
        id: "partner_id",
        Header: "Partner",
        accessor: "partner_id",
      },
    ],
    []
  );

  return <Table columns={columns} />;
};
