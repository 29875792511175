import { Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { dataProvider } from "@pankod/refine-supabase";
import { supabaseClient } from "utility";
import authProvider from "./authProvider";
import "index.css";
import { SignUp } from "pages/signup";
import { Login } from "pages/login";
import { ToastContainer } from "react-toastify";
import { PartnersList, PartnerShow, PartnerEdit } from "pages/partners";
import { ContactsList } from "pages/contacts";
import { TrainingsList } from "pages/trainings";
import { CoursesList } from "pages/courses";
import { HubsList } from "pages/hubs";
import { ActivitiesList } from "pages/activities";

import { Layout } from "components/Layout";
import {
  UserGroupIcon,
  BookOpenIcon,
  AcademicCapIcon,
  GlobeIcon,
  TemplateIcon,
  PuzzleIcon,
} from "@heroicons/react/outline";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Refine
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <SignUp />,
            path: "/signup",
          },
        ] as typeof routerProvider.routes,
      }}
      dataProvider={dataProvider(supabaseClient)}
      authProvider={authProvider}
      resources={[
        {
          name: "partners",
          icon: <UserGroupIcon className="h-6" />,
          list: PartnersList,
          show: PartnerShow,
          edit: PartnerEdit,
        },
        {
          name: "contacts",
          icon: <BookOpenIcon className="h-6" />,
          list: ContactsList,
        },
        {
          name: "trainings",
          icon: <AcademicCapIcon className="h-6" />,
          list: TrainingsList,
        },
        {
          name: "courses",
          icon: <TemplateIcon className="h-6" />,
          list: CoursesList,
        },
        { name: "hubs", icon: <GlobeIcon className="h-6" />, list: HubsList },
        {
          name: "activities",
          icon: <PuzzleIcon className="h-6" />,
          list: ActivitiesList,
        },
      ]}
      LoginPage={Login}
      Layout={({ children }) => (
        <Layout>
          {children}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Layout>
      )}
    />
  );
}

export default App;
