import React, { useState } from "react";
import { Column } from "@pankod/refine-react-table";
import { SelectColumnFilter, Table } from "components/Table";
import { EyeIcon, PencilAltIcon } from "@heroicons/react/solid";
import { useNavigation } from "@pankod/refine-core";
import Modal from "components/elements/Modal";
import { PartnerShow } from "./show";

export const PartnersList: React.FC = () => {
  const { show, edit } = useNavigation();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
      },
      {
        id: "partner_name",
        Header: "Title",
        accessor: "partner_name",
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "contains",
      },

      {
        id: "priority",
        Header: "Priority",
        accessor: "priority",
        Filter: SelectColumnFilter,
        filter: "contains",
      },
      {
        id: "county",
        Header: "County",
        accessor: "county",
      },
      {
        id: "country",
        Header: "Country",
        accessor: "country",
      },
      {
        id: "action",
        Header: "Action",
        accessor: "id",
        Cell: ({ value, row: { original } }: any) => (
          <div className="flex gap-2">
            <button
              className=" text-xs font-medium leading-tight transition duration-150 ease-in-out hover:bg-indigo-500 hover:text-white"
              onClick={(e) => {
                setModalData(original);
                setShowModal(true);
              }}
            >
              <EyeIcon className="h-6" />
            </button>

            <button
              className="text-xs font-medium leading-tight transition duration-150 ease-in-out hover:bg-indigo-500 hover:text-white"
              onClick={() => edit("partners", value)}
            >
              <PencilAltIcon className="h-6" />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {/* @ts-ignore */}
        <PartnerShow partnerData={modalData} />
      </Modal>
      <Table columns={columns} />
    </>
  );
};
