import React, { useState } from "react";
import { Column } from "@pankod/refine-react-table";
import { Table } from "components/Table";
import { EyeIcon, PencilAltIcon } from "@heroicons/react/solid";
import { CourseShow } from "./show";
import Modal from "components/elements/Modal";
import { useNavigation } from "@pankod/refine-core";

export const CoursesList: React.FC = () => {
  const { show, edit } = useNavigation();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
      },

      {
        id: "curriculum",
        Header: "curriculum",
        accessor: "curriculum",
      },
      {
        id: "code",
        Header: "code",
        accessor: "code",
      },
      {
        id: "practice",
        Header: "practice",
        accessor: "practice",
      },
      {
        id: "target_audience",
        Header: "target audience",
        accessor: "target_audience",
      },
      {
        id: "action",
        Header: "Action",
        accessor: "id",
        Cell: ({ value, row: { original } }: any) => (
          <div className="flex gap-2">
            <button
              className=" text-xs font-medium leading-tight transition duration-150 ease-in-out hover:bg-indigo-500 hover:text-white"
              onClick={(e) => {
                setModalData(original);
                setShowModal(true);
              }}
            >
              <EyeIcon className="h-6" />
            </button>

            <button
              className="text-xs font-medium leading-tight transition duration-150 ease-in-out hover:bg-indigo-500 hover:text-white"
              onClick={() => edit("partners", value)}
            >
              <PencilAltIcon className="h-6" />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {/* @ts-ignore */}
        <CourseShow courseData={modalData} />
      </Modal>
      <Table columns={columns} />
    </>
  );
};
