// import { IPost, ICategory } from "interfaces";

export const HubShow: React.FC = ({ hubData }: any) => {
  return (
    <div className="container mx-auto p-8">
      <div className="mb-6">
        <label className="mb-2 block text-sm font-medium">Hub Name</label>
        <input
          value={hubData?.hub_name}
          disabled
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
        />
      </div>
      <div className="mb-6">
        <label className="mb-2 block text-sm font-medium">Location</label>
        <input
          value={hubData?.location}
          disabled
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
        />
      </div>
      <div className="mb-6">
        <label className="mb-2 block text-sm font-medium">status</label>
        <input
          value={hubData?.status}
          disabled
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
        />
      </div>
      {/* <div className="mb-6">
          <label className="mb-2 block text-sm font-medium">Description</label>
          <textarea
            value={hubData?.description}
            disabled
            rows={10}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          />
        </div> */}
    </div>
  );
};
