import { useMenu, useNavigation, useLogout } from "@pankod/refine-core";
import { useLocation } from "react-router-dom";
// import routerProvider from "@pankod/refine-react-router-v6";

// const { Link } = routerProvider;
import { LogoutIcon } from "@heroicons/react/outline";

export const Layout: React.FC = ({ children }) => {
  const { menuItems } = useMenu();
  const { push } = useNavigation();
  const { pathname } = useLocation();
  const { mutate: logout } = useLogout();

  return (
    // <div className="flex h-screen flex-row w-full bg-green-500 overflow-hidden">
    //   <div className="lg:w-60 bg-ultramarine-violet text-white flex flex-col gap-4 flex-none">
    //     <div className="flex justify-center p-2 lg:p-4">
    //       <img
    //         className="w-10 h-10 p-2 bg-white rounded-full "
    //         src="./images/logo.png"
    //         alt="Logo"
    //       />
    //     </div>
    //     <div className=" h-full flex flex-col justify-between">
    //       <div className="flex flex-col">
    //         {menuItems.map(({ name, label, icon, route }) => (
    //           <div
    //             key={name}
    //             className={`cursor-pointer ${
    //               pathname.split("/").pop() === name ? "bg-summer-sky" : ""
    //             } py-2 pl-4 flex`}
    //           >
    //             <a
    //               className="flex h-8 gap-4 items-center"
    //               onClick={() => push(route || "")}
    //             >
    //               {icon}
    //               <span className="hidden lg:block">{label ?? name}</span>
    //             </a>
    //           </div>
    //         ))}
    //       </div>
    //       <div className="h-12 border m-2 rounded flex items-center justify-center text-sm  cursor-pointer hover:bg-summer-sky hover:border-0">
    //         <a
    //           onClick={() => logout()}
    //           className="flex items-center justify-center gap-4"
    //         >
    //           <LogoutIcon className="h-6" />{" "}
    //           <span className="hidden lg:block">logout</span>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="flex-auto bg-black-haze p-4">{children}</div>
    // </div>
    <div>
      <aside className="ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-ultramarine-violet transition duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]">
        <div>
          <div className="-mx-6 px-6 py-4">
            <img
              className="w-10 h-10 p-2 bg-white rounded-full "
              src="./images/logo.png"
              alt="Logo"
            />
          </div>

          <ul className="space-y-2 tracking-wide mt-8">
            {menuItems.map(({ name, label, icon, route }) => (
              <li>
                <a
                  onClick={() => push(route || "")}
                  className={`px-4 py-3 flex items-center space-x-4 cursor-pointer ${
                    pathname.split("/").pop() === name ? "bg-gradient-to-r" : ""
                  } rounded-md text-white group hover:bg-gradient-to-r from-sky-600 to-cyan-400`}
                >
                  {icon}
                  <span className="group-hover:text-white">
                    {label ?? name}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
          <button
            onClick={() => logout()}
            className="px-4 py-3 flex items-center space-x-4 rounded-md text-white group"
          >
            <LogoutIcon className="h-6" />
            <span className="group-hover:text-white">Logout</span>
          </button>
        </div>
      </aside>
      <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
        <div className="sticky top-0 h-16 border-b bg-white lg:py-2.5">
          <div className="px-6 flex items-center justify-between space-x-4 2xl:container">
            <h5
              hidden
              className="text-2xl text-gray-600 font-medium lg:block capitalize"
            >
              {pathname.split("/").pop()}
            </h5>
            <button className="w-12 h-16 -mr-2 border-r lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 my-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            <div className="flex space-x-4">
              <button
                aria-label="notification"
                className="w-10 h-10 rounded-xl border bg-gray-100 focus:bg-gray-100 active:bg-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-auto text-gray-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="px-6 pt-6 2xl:container">
          <div className="flex justify-center items-center h-[80vh] border-2 border-dashed border-gray-300 rounded-xl">
            {/* table start */}
            {children}
            {/* table end */}
          </div>
        </div>
      </div>
    </div>
  );
};
