import React, { useState } from "react";
import { Column } from "@pankod/refine-react-table";
import { Table } from "components/Table";
import { EyeIcon, PencilAltIcon } from "@heroicons/react/solid";
import Modal from "components/elements/Modal";
import { useNavigation } from "@pankod/refine-core";
import { HubShow } from "./show";

export const HubsList: React.FC = () => {
  const { show, edit } = useNavigation();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id",
      },
      {
        id: "hub_name",
        Header: "Name",
        accessor: "hub_name",
      },
      {
        id: "location",
        Header: "location",
        accessor: "location",
      },

      {
        id: "status",
        Header: "Status",
        accessor: "status",
      },
      {
        id: "action",
        Header: "Action",
        accessor: "id",
        Cell: ({ value, row: { original } }: any) => (
          <div className="flex gap-2">
            <button
              className=" text-xs font-medium leading-tight transition duration-150 ease-in-out hover:bg-indigo-500 hover:text-white"
              onClick={(e) => {
                setModalData(original);
                setShowModal(true);
              }}
            >
              <EyeIcon className="h-6" />
            </button>

            <button
              className="text-xs font-medium leading-tight transition duration-150 ease-in-out hover:bg-indigo-500 hover:text-white"
              onClick={() => edit("partners", value)}
            >
              <PencilAltIcon className="h-6" />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {/* @ts-ignore */}
        <HubShow hubData={modalData} />
      </Modal>
      <Table columns={columns} />
    </>
  );
};
